var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      style: {
        paddingTop:
          (_vm.data.style.pagePaddingTop
            ? _vm.data.style.pagePaddingTop
            : "0") + "px",
        paddingRight:
          (_vm.data.style.pagePaddingRight
            ? _vm.data.style.pagePaddingRight
            : "0") + "px",
        paddingBottom:
          (_vm.data.style.pagePaddingBottom
            ? _vm.data.style.pagePaddingBottom
            : "0") + "px",
        paddingLeft:
          (_vm.data.style.pagePaddingLeft
            ? _vm.data.style.pagePaddingLeft
            : "0") + "px",
      },
    },
    [
      _c(
        "video",
        {
          style: {
            borderRadius: _vm.data.style.chamfer == "circle" ? "10px" : "",
          },
          attrs: {
            loop: true,
            src: _vm.data.style.videoUrl,
            width: "100%",
            controls: _vm.controls,
            autoplay: _vm.data.style.voluntarilyPlay,
            poster: _vm.data.style.coverImg,
            preload: "",
          },
          on: { loadedmetadata: _vm.loadedmetadata },
        },
        [_vm._v(" your browser does not support the video tag ")]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }